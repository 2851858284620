import { baseConsoleRoutes } from './index'
import baseConfig from '~/baseConfig/index'
import { confirmProjectLocale } from '~/baseUtils'
const routersNames = [
    { id: '1', defaultName: 'AccountSettings', defaultSort: 1, nameExtend: {}, sortExtend: { EXP: 3, FDA: 3, SIF: 1, LYS: 1 } },
    { id: '1-1', defaultName: 'BasicInformation', nameExtend: {} },
    { id: '1-2', defaultName: 'SecuritySettings', nameExtend: {} },
    { id: '1-3', defaultName: '实名认证', nameExtend: {} },
    { id: '2', defaultName: 'companySetting', defaultSort: 2, nameExtend: {}, sortExtend: { EXP: 1, FDA: 1, SIF: 2, LYS: 1 } },
    { id: '2-1', defaultName: 'companyInfo', nameExtend: {} },
    { id: '2-2', defaultName: 'companyPicture', nameExtend: {}, notExtend: { OSM: 0 } },
    { id: '2-3', defaultName: 'companyCertificate2', nameExtend: {} },
    { id: '2-4', defaultName: 'companyVideo', nameExtend: {} },
    { id: '2-5', defaultName: 'accountNav', nameExtend: {} },
    { id: '2-6', defaultName: 'companyBusiness', nameExtend: {} },
    { id: '2-7', defaultName: 'enterpriseConcern', nameExtend: {} },
    { id: '2-8', defaultName: '收藏夾', nameExtend: {} },
    { id: '2-10', defaultName: '账号管理', nameExtend: {} },
    { id: '3', defaultName: 'meetingManage', defaultSort: 3, nameExtend: {}, sortExtend: { EXP: 2, FDA: 2 } },
    { id: '3-1', defaultName: 'myMeeting', nameExtend: {} },
    { id: '3-2', defaultName: 'boothManagement', nameExtend: {} },
    { id: '3-3', defaultName: 'cardCase', nameExtend: {} },
    { id: '3-4', defaultName: 'cardAdmin', nameExtend: {} },
    { id: '3-5', defaultName: 'ParticipantManagement', nameExtend: {} },
    { id: '3-6', defaultName: '嘉宾/主持人设置', nameExtend: {} },
    { id: '3-7', defaultName: 'Livebroadcastmanagement', nameExtend: {} },
    { id: '3-8', defaultName: '直播创建&修改', nameExtend: {} },
    { id: '3-9', defaultName: 'mymeetinglive', nameExtend: {} },
    { id: '3-10', defaultName: '展台预览', nameExtend: {} },
    { id: '3-11', defaultName: 'businessRedEnvelopeAdmin', nameExtend: {} },
    { id: '3-13', defaultName: 'mianbaoxie2', nameExtend: {} },
    { id: '3-14', defaultName: 'accountRecords', nameExtend: {} },
    { id: '3-12', defaultName: 'menu_cash', nameExtend: {} },
    { id: '3-15', defaultName: 'myCollection', nameExtend: {} },
    { id: '3-16', defaultName: '洽谈记录', nameExtend: {} },
    { id: '3-17', defaultName: 'RedPacketCollectionList', nameExtend: {} },
    { id: '3-18', defaultName: 'cash_lq_list', nameExtend: {} },
		{ id: '3-19', defaultName: 'Negotiationsetting', nameExtend: {} },
    { id: '4', defaultName: '人才中心', defaultSort: 4, nameExtend: {}, sortExtend: { SIF: 4, LYS: 4 } },
    { id: '4-1', defaultName: '职位管理', nameExtend: {} },
    { id: '5', defaultName: '我的申请', defaultSort: 5, nameExtend: {}, sortExtend: { SIF: 5, LYS: 5 } },
    { id: '5-1', defaultName: '入会申请', nameExtend: {} },
    { id: '5-2', defaultName: '活动申请', nameExtend: {} },
    { id: '6', defaultName: 'meetingOnetoOneContact', defaultSort: 6, nameExtend: {}, sortExtend: { SIF: 3, LYS: 3 } },
    { id: '6-1', defaultName: 'mymeetinglive', nameExtend: {} },
    // 7是玉漭后台
    { id: '7', defaultName: '交易管理', defaultSort: 7, nameExtend: {}, sortExtend: {} },
    { id: '7-1', defaultName: '', nameExtend: {} },
    { id: '7-2', defaultName: '我的委托', nameExtend: {} },
    { id: '7-3', defaultName: '我的委托详情', nameExtend: {} },
    { id: '7-4', defaultName: '资料库', nameExtend: {}},
    { id: '7-5', defaultName: '地址管理', nameExtend: {}},
    { id: '2-9', defaultName: '开票信息', nameExtend: {}},
    { id: '7-6', defaultName: '我的委托详情（已成单）', nameExtend: {}},
    { id: '8', defaultName: '直通海外', defaultSort: 8, nameExtend: {}, sortExtend: {} },
    { id: '8-1', defaultName: '我的发盘', nameExtend: {}},
    { id: '8-2', defaultName: '发盘详情', nameExtend: {}},
]
function handleRouterName() {
    let router = baseConsoleRoutes.concat([]);
    let PJID = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJID
    router.forEach(item => {
        let selfMatchIng = routersNames.find(ele => {
            return ele.id == item.meta.nameId
        });
        item.meta.label = selfMatchIng.nameExtend[PJID] || selfMatchIng.defaultName
        let parentMatchIng = routersNames.find(ele => {
            return ele.id == item.meta.parentId
        })
        item.meta.parent = parentMatchIng.nameExtend[PJID] || parentMatchIng.defaultName
    })
    return router;
}

function handleRouteSort(router) {
    let PJID = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJID
    let _router = router;
    _router.forEach(item => {
        let parentId = item.children[0].meta.parentId;
        let _config = routersNames.find(_item => {
            return _item.id == parentId
        })
        item.sortId = _config.sortExtend[PJID] || _config.defaultSort
    })
    let returnRouter = _router.sort((a, b) => {
        return a.sortId - b.sortId
    })
    return returnRouter
}

function handleStructure() {
    let router = handleRouterName();
    let parentKey = [...new Set(router.map(item => item.meta.parent))]
    let structure = [];
    parentKey.forEach(item => {
        let routerObj = {
            label: item,
            children: router.filter(routeItem => { return routeItem.meta.parent == item })
        }
        structure.push(routerObj)
    })
    let sortRouter = handleRouteSort(structure)
    return sortRouter;
}

function handleRouterAuth(userAuth) {
    let platform = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].PJID;
    let routers = handleStructure();
    routers.forEach(item => {                                            //先过滤平台,只针对二级菜单
        item.children = item.children.filter(node => {
            return node.meta.platform.indexOf(platform) != -1 || node.meta.platform.indexOf('ALL') != -1 && (!node.meta.notShow || node.meta.notShow) && node.meta.notShow != platform
        })
    });
    routers.forEach((item) => {                                          //过滤权限及处理hiddenInMenu及隐藏对应环境
        for (let i = item.children.length - 1; i >= 0; i--) {
            let ele = item.children[i];
            if (ele.meta.auth && userAuth.indexOf(ele.meta.auth) == -1 || ele.meta.hiddenInMenu || confirmLocaleHidden(ele)) {
                item.children.splice(i, 1);
            }
        }
    });
    for (let i = routers.length - 1; i >= 0; i--) {                     //将没有子菜单的父菜单过滤
        if (routers[i].children.length == 0) {
            routers.splice(i, 1);
        }
    }
    return routers;
}
function confirmLocaleHidden(item) {

    let currentLocale = confirmProjectLocale();
    if (currentLocale == 'en') {
        if (item.meta.hiddenInEnLocale) {
            return true
        }
    }
    if (currentLocale == 'zh') {
        if (item.meta.hiddenInZhLocale) {
            return true
        }
    }
}
export let routers = handleStructure()

export let getAuthRouters = function (auth) {
    return handleRouterAuth(auth);
};
export default {
    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/plc/qldconsoleLogo.png',
        bgColor: '#3384B1',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    pageConfig: {
        companyPage: {
            url: '/companyDetail',
            isEncode: true,
        },
        signPage: {
            url: '/sign'
        }
    },
    accountConfig: {
        notUseWx: true
    },
    basicInfoLocale: "all",//en all
}
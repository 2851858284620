import { handleToVideoChatPage, handleToVideoChatPageOld, handleToMultiChatPage } from '~/baseUtils'
export default {

    data() {
        return {

        }
    },
    methods: {
        goToCompanyPage(params) {
            if (!this.BACCONFIG.pageConfig) return;
            let page_url = this.BACCONFIG.pageConfig.companyPage.url;
            let _params;
            if (this.BACCONFIG.pageConfig.companyPage.isEncode) {
                _params = {
                    parameter: this._encode(params)
                }
            } else {
                _params = params
            }
            let { href } = this.$router.resolve({
                path: page_url,
                query: _params
            })

            window.open(href, '_blank')
        },
        goToVchatPage(channel_id, user_id, chat_type, isHasBack, startTime, endTime, srartTips, endTips) {
            if (this.$moment().isBefore(this.$moment(startTime * 1000).add("-10", "m"), 'seconds')) {
                this.$message.warning(srartTips ? srartTips : this.$t('enterMeetingBeforeTen'))
            } else if (this.$moment().isAfter(this.$moment(endTime * 1000).add("5", "m"), 'seconds')) {
                this.$message.warning(endTips ? endTips : this.$t('chatTimeEnd'))
            } else {
                handleToVideoChatPage(channel_id, user_id, chat_type, isHasBack)
            }
        },
        goToVchatPagetips(startTime, endTime, srartTips, endTips, configs) {
            if (this.$moment().isBefore(this.$moment(startTime * 1000).add("-10", "m"), 'seconds')) {
                this.$message.warning(srartTips ? srartTips : this.$t('enterMeetingBeforeTen'))
            } else if (this.$moment().isAfter(this.$moment(endTime * 1000).add("5", "m"), 'seconds')) {
                this.$message.warning(endTips ? endTips : this.$t('chatTimeEnd'))
            } else {
                return configs.todo();
            }
        },
        goToMultiPage(channel_id, user_id, startTime, endTime, srartTips, endTips) {
            if (this.$moment().isBefore(this.$moment(startTime * 1000).add("-10", "m"), 'seconds')) {
                this.$message.warning(srartTips ? srartTips : this.$t('enterMeetingBeforeTen'))
            } else if (this.$moment().isAfter(this.$moment(endTime * 1000).add("5", "m"), 'seconds')) {
                this.$message.warning(endTips ? endTips : this.$t('chatTimeEnd'))
            } else {
                handleToMultiChatPage(channel_id, user_id)
            }
        }
    },
    computed: {
        BACCONFIG() {
            return this.$store.state.baseConsole.config
        },
        USER_STATUS() {
            //用户状态  1.未登录，2.个人用户，3.普通公司管理员，4.普通公司用户，5.普通公司未审核，6.会员公司管理员，7.会员公司用户，8.会员公司未审核
            if (!this.IS_LOGIN) {                                        //未登录
                return 1;
            }
            if (this.IS_LOGIN && !this.USER_INFO.company_id) {            //个人用户（未绑定公司）
                return 2;
            }
            if (this.IS_LOGIN && this.USER_INFO.company_id && this.USER_INFO.company_bind_status == 2 && !this.USER_INFO.aila_no && this.USER_INFO.is_admin) {              //普通公司管理员
                return 3;
            }
            if (this.IS_LOGIN && this.USER_INFO.company_id && this.USER_INFO.company_bind_status == 2 && !this.USER_INFO.aila_no) {              //普通公司用户
                return 4;
            }
            if (this.IS_LOGIN && this.USER_INFO.company_id && this.USER_INFO.company_bind_status != 2 && !this.USER_INFO.aila_no) {              //普通公司未审核
                return 5;
            }
            if (this.IS_LOGIN && this.USER_INFO.company_id && this.USER_INFO.company_bind_status == 2 && this.USER_INFO.aila_no && this.USER_INFO.is_admin) {          //会员公司管理员
                return 6;
            }
            if (this.IS_LOGIN && this.USER_INFO.company_id && this.USER_INFO.company_bind_status == 2 && this.USER_INFO.aila_no) {          //会员公司用户
                return 7;
            }
            if (this.IS_LOGIN && this.USER_INFO.company_id && this.USER_INFO.company_bind_status != 2 && this.USER_INFO.aila_no) {          //会员公司未审核
                return 8;
            }
        },
    },

}

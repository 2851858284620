<template>
  <div id="overseasMeeting">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import defaultlayout from "./layouts/default.vue";
import signlayout from "./layouts/sign.vue";
import emptylayout from "./layouts/empty.vue";
import baseConsolelayout from "~bac/layouts/default.vue";

export default {
  metaInfo() {
    return {
      title: 'WFA BI-LATERAL VIRTUAL MEETINGS',
    };
  },
  components: {
    defaultlayout,
    baseConsolelayout,
    signlayout,
    emptylayout,
  },
  data() {
    return {
      default_layout: "default", //设置layout
      showDialog: false,
      isShow: false,
    };
  },
  methods: {},
  mounted() {},
  computed: {
    layout() {
      return (this.$route.meta.layout || this.default_layout) + "layout";
    },
  },
};
</script>
<style lang="less">
.app_no_apply {
  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
  }
}
</style>

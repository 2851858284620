export default {
  namespaced: true,
  actions: {
    async saveCompanyMsg(context, params) {//保存公司信息
      let { data } = await axios.post("company/api/info/save", params)
      return data
    },
    async getQualification(context, params) {//查询公司资质信息
      let { data } = await axios.post("company_vip/api/company/qualification", params)
      return data
    },
    async company_qualificationUpload(context, params) {//上次或修改公司资质
      let { data } = await axios.post("media/api/company_qualification/upload", params)
      return data
    },
    async companyVipApply(context, params) {//前台新会员申请
      let { data } = await axios.post("company_vip/api/vip/apply", params)
      return data
    },
    async getHomeBanner(context, params) {// 首页banner
      let { data } = await axios.get("media/api/home_page/banner", { params })
      return data
    },
    async getStaticContent(context, params) {// 首页活动通知、协会动态、行业信息、政策法规 列表查询接口
      let { data } = await axios.post("siffa/api/siffa_static_content/list", params)
      return data
    },
    async getSifCompanyVip(context, params) {// 首页会员动态
      let { data } = await axios.post("siffa/api/siffa/company_vip", params)
      return data
    },
    async getAdvertisingList(context, params) {// 首页广告
      let { data } = await axios.post("siffa/api/advertising/info", params)
      return data
    },
    async applyEnroll(context, params) {// 申请
      let { data } = await axios.post("siffa/api/siffa_event_apply/save", params)
      return data
    },
    async nowStatus(context, params) {// 获取当前申请状态
      let { data } = await axios.post("siffa/api/siffa_event_apply/detail", params)
      return data
    },
    async companyVipInfo(context, params) {//会员信息查询
      let { data } = await axios.get("company_vip/api/company_vip/info", { params })
      return data
    },
    async downloadList(context, params) {// 下载列表
      let { data } = await axios.post("siffa/api/siffa_download/list", params)
      return data
    },
    async suggestionSave(context, params) {// 联系我们输入建议
      let { data } = await axios.post("siffa/api/siffa_suggestion/save", params)
      return data
    },
    async memberCompanysearch(context, params){//会员公司查询
      let {data}= await axios.post("siffa/api/siffa_member_dynamic/list",params)
      return data
    },
    async getunitTypeList(context, params){//获取会长副会长单位数据
      let {data}=await axios.post("admin_siffa/api/admin_siffa_company_genre/list",params)
      return data
    },
    async getJobRecommend(context, params) { //首页·校企合作
      let { data } = await axios.post('job/api/newest/position', params);
      return data;
    },
    async getWarning(context, params) {
      //获取风险列表
      let { data } = await axios.post("company/api/warning_info/list", params);
      return data;
    },
    async getHomeWarning(context, params) {
      //获取风险列表首页
      let { data } = await axios.get("company/api/warning_info/dynamic", { params });
      return data;
    },
  }
}

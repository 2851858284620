export default {

    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/mpa/baseLogo.png',
        bgColor: 'rgb(4, 157, 237)',
        textColor: '#fff',
        activeTextColor: 'rgb(4, 157, 237)'
    },
    accountConfig: {
        notUseWx: true
    },
    pageConfig: {
        companyPage: {
            url: '/companyDetail',
            isEncode: true,
        },
    }
}
export default {

    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/Logo.png',
        bgColor: '#192F59',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    accountConfig: {
        notUseWx: true
    },
    pageConfig: {
        companyPage: {
            url: '/companyDetail',
            isEncode: true,
        },
    }
}
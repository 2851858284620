export default {
    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/lys/logo.png',
        bgColor: '#30486b',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    // pageConfig: {
    //     companyPage: {
    //         url: '/companyDetail',
    //         isEncode: true,
    //     },
    //     signPage: {
    //         url: '/sign'
    //     }
    // },
    accountConfig: {
        notUseWx: true
    },
    basicInfoLocale: "all",//en All
}
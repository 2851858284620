import { render, staticRenderFns } from "./navMenu.vue?vue&type=template&id=824fa7c6&scoped=true&"
import script from "./navMenu.vue?vue&type=script&lang=js&"
export * from "./navMenu.vue?vue&type=script&lang=js&"
import style0 from "./navMenu.vue?vue&type=style&index=0&id=824fa7c6&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "824fa7c6",
  null
  
)

export default component.exports
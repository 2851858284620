import baseConfig from '~/baseConfig/index'

export default {
  actions:{
    async getOverseasCountryList(context, params){//直通海外国家列表
      let {data}=await axios.post('inquiry/api/siffa_overseas/country_city',params)
      return data
    },
    async getOverseasList(context, params){//直通海外列表
      let {data}=await axios.post('inquiry/api/siffa_overseas/company_list',params)
      return data
    },
    async consignorOffer(context, params){//一键询盘
      let {data}=axios.post("inquiry/api/inquiry/creat_v2",params)
      return data
    }
  }
}
<template>
  <div class="pageFooter">
    <div class="footerContent content_area">
      <div class="content">
        <div class="leftBox">
          <div class="leftBoxTitle">
            <div class="countryIn smallTitle">Head Office</div>
            <div class="countryOut smallTitle">Support</div>
          </div>
          <div class="boxContent">
            <div class="boxItem">
              <div class="contentFont">World Freight Alliance</div>
              <div class="contentFont">Brussels Airport</div>
              <div class="contentFont">
                Bedrijvenzone Machelen Cargo 726/730
              </div>
              <div class="contentFont">1830 - BELGIUM</div>
            </div>
            <div class="boxItem">
              <div class="contentFont">Ph: +44 7921474305</div>
              <div class="contentFont">info@wfalliance.com</div>
              <div class="contentFont">ron@ailaworld.com</div>
            </div>
          </div>
        </div>
        <div class="rightBox"></div>
      </div>
      <div class="foot">
        CopyRight &copy; AI Logistics Technology (Shanghai) Co., Ltd. All Rights Reserved 
        <!-- <a href="http://beian.miit.gov.cn/">沪ICP备19006997号-8 </a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img_url:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/qrCode.jpg",
    };
  },
};
</script>

<style scoped lang="less">
.pageFooter {
  //height: 252px;
  background: #3489ff;
  .footerContent {
    padding-top: 20px;
    padding-bottom: 44px;
    .titleFont {
      font-size: 16px;
      font-weight: 800;
      color: #ffffff;
    }
    .contentFont {
      color: #ffffff;
      margin-top: 17px;
    }
    .content {
      display: flex;
      margin-top: 24px;
      justify-content: space-between;
      .leftBox {
        width: 963px;
        .leftBoxTitle {
          display: flex;
          .countryIn {
            width: 350px;
          }
        }
        .smallTitle {
          color: #fff;
          font-weight: 600;
          font-size: 18px;
        }
        .boxContent {
          //   display: flex;
          //   justify-content: space-between;
          .boxItem {
            float: left;
            width: 350px;
          }
        }
      }
      .rightBox {
        width: 157px;
        margin-right: 30px;
        .img {
          margin: 16px 0;
          z-index: 10;
        }
        img {
          width: 100%;
        }
      }
    }
    .foot {
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      margin-top: 60px;
      a {
        color: #ffffff;
      }
    }
    .colorFFF {
      color: #ffffff;
    }
  }
}
</style>
export default {
    actions: {
        async getUserList(contact, params) {
            //公司所有联系人
            let { data } = await axios.get("job/api/user/list", { params });
            return data;
        },
        // 职位列表 
        async job_positionList(context, params) {               //发布职位列表
            let { data } = await axios.post('job/api/job_positon/manage', params);
            return data;
        },
        // 职位列表 
        async job_positionSave(context, params) {               //发布职位列表
            let { data } = await axios.post('job/api/job_position/save', params);
            return data;
        },
        async job_positiondetail(context, params) {               //发布职位详情
            let { data } = await axios.post('job/api/job_position/detail', params);
            return data;
        },
    }
}
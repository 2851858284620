import baseConfig from '~/baseConfig'
const BASE_URL = baseConfig.projectConfig[process.env.VUE_APP_PROJECT_NAME].apis[process.env.VUE_APP_API_ENV][0];
axios.interceptors.request.use((config) => {
    
    if (config.url.indexOf('http') == -1) {
        config.url = `${BASE_URL}${config.url}`
    }
    for (let key in config.data) {    
        if (config.data && !config.data._NOCLEAR) {
            if (config.data[key] === '' || config.data[key] === undefined || config.data[key] === null) {
                if (config.data._EMPTYITEM && config.data._EMPTYITEM.indexOf(key) != -1) {

                } else {
                    delete config.data[key]
                }
            }
        }
    }
    if (config.data && config.data._DELETEITEM) {
        config.data._DELETEITEM.forEach(item => {
            delete config.data[item]
        });
        delete config.data._DELETEITEM;
    }
    if (config.data && config.data._EMPTYITEM) delete config.data._EMPTYITEM;
    if (config.data && config.data._NOCLEAR) delete config.data._NOCLEAR;

    config.data = Qs.stringify(config.data)
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
    return config;
}, function (error) {

    return Promise.reject(error);
});

axios.interceptors.response.use((data) => {
    return data;
})
export default {

    navConfig: {
        logoUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/avatar/img/logo.27a9a9de.png',
        bgColor: '#027FFF',
        textColor: '#fff',
        activeTextColor: '#fff'
    },
    accountConfig: {
        notUseWx: false
    },
    basicInfoLocale: "zh",//en All
}
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
Vue.config.productionTip = false
import * as filters from './plugins/filters'
import './assets/style/elementStyle/index.css'
import '~/basePlugins'
import store from './store/index'
import { getI18n } from '~/basePlugins/i18n'
import '~/basePlugins/indexBaseConsole.js'
import { initUserIdAndInfo, checkIsNotInitPage } from '~/baseUtils'
import mixin from './mixin'
// import $_log  from '~/basePlugins/log'
import '~bac/assets/style/baseConsole.less'
import './assets/style/base.less'
// Vue.prototype.$log = $_log;
Vue.mixin(mixin);
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
});
(async () => {
    if (checkIsNotInitPage()) {
        await Promise.all([await initUserIdAndInfo(store, 'baseStore/baseSign_getUserInfo'), await store.dispatch('baseStore/getMeeingBasicInfo', {
            meeting_id: store.state.meeting_id
        })])
        if (store.state.baseStore.userInfo && store.state.baseStore.userInfo.id) {
            await store.dispatch("baseStore/baseSign_getMeetingEnrollInfo", {
                meeting_id: store.state.meeting_id,
                user_id: store.state.baseStore.userInfo.id,
                company_id: store.state.baseStore.userInfo.company_id
            });
            // await store.dispatch(
            //     "baseConsole/getnegotationsetList",
            //     {
            //         meeting_id: store.state.meeting_id,
            //         user_id: store.state.baseStore.userInfo.id,
            //         nopage: 1,
            //         is_self: true,
            //     }
            // );
        };
    }
    new Vue({
        render: h => h(App),
        router,
        store,
        i18n: await getI18n()
    }).$mount('#app')
})()


import consolePreview from './consolePrview'
import expoMeeting from './expoMeeting'
import fudaMeeting from './fudaMeeting'
import tradeFederation from './tradeFederation'
import siffa from './siffa'
import lySociety from './lySociety'
import scbSociety from './scbSociety'
import overseasMeeting from './overseasMeeting'
import ymPcClient from './ymPcClient'
import qlcSociety from './qlcSociety'
import mpaSociety from './mpaSociety'
export default {
    consolePreview,
    expoMeeting,
    fudaMeeting,
    tradeFederation,
    siffa,
    lySociety,
    scbSociety,
    overseasMeeting,
    ymPcClient,
    qlcSociety,
    mpaSociety
}
<template>
  <div>
    <div class="title">
      <p class="timeStart content_area">
        Welcome to WFA BI-LATERAL VIRTUAL MEETINGS !
      </p>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: null,
    };
  },
  mounted() {
    let m1 = this.$moment("2021-05-24"),
      m2 = this.$moment();
    let res = m1.diff(m2, "day");
    this.time = res;
  },
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  height: 62px;
  background: #027fff;
  .timeStart {
    flex: 1;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    color: #aed7ff;
    line-height: 62px;
    span {
      color: #fff;
    }
  }
}
</style>
import Vue from 'vue'
import baseStore from '~/baseStore'
import baseConsole from '~bac/store'
import API_Sign from './API_sign'
import API_ymp_company from './API_company'
import API_freight from './API_freight'
import API_bookingSpace from './API_bookingSpace'
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {


        isApplyShow: false,
    },
    mutations: {

    },
    actions: {

    },
    modules: { baseStore, baseConsole, API_Sign, API_ymp_company, API_freight, API_bookingSpace }
})

export default store

<template>
  <div class="consoleNav-container">
    <div :class="LOCALE">
      <el-menu
        class="el-menu-vertical"
        :router="true"
        :background-color="BACCONFIG.navConfig.bgColor"
        :text-color="BACCONFIG.navConfig.textColor"
        :active-text-color="BACCONFIG.navConfig.activeTextColor"
        :default-openeds="openIndex"
      >
        <el-submenu
          :index="index + ''"
          v-for="(item, index) in menuData"
          :key="index"
        >
          <template slot="title">
            <span>{{ $t(item.label) }}</span>
          </template>
          <template v-for="_item in item.children">
            <template>
              <el-menu-item
                :index="_item.path"
                :key="_item.path"
                :class="{ active: currentPath == _item.path }"
              >
                <span>{{ $t(_item.meta.label) }}</span>
              </el-menu-item>
            </template>
          </template>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { getAuthRouters, routers } from "~bac/router/handle";
export default {
  mounted() {
    this.getOpenIndex();
  },
  props: ["currentPath"],
  data() {
    return {
      routers,
      openIndex: ["0"],
    };
  },
  computed: {
    menuData() {
      let hasAuth = this.$store.state.baseStore.auth.MENUAUTH.concat([]);
      let menuData = getAuthRouters(hasAuth);
      return menuData;
    },
  },
  methods: {
    getOpenIndex() {
      let _index = 0;
      this.menuData.forEach((element, index) => {
        element.children.forEach((item) => {
          if (item.path == this.currentPath) {
            _index = index;
          }
        });
      });

      this.openIndex = [_index + ""];
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-submenu__title {
  height: 48px;
  line-height: 48px;
}
.en {
  /deep/ .el-submenu__title {
    padding-left: 10px !important;
  }
}
.el-menu {
  border-right: 0 none;
  .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
}
.en {
  .el-menu-item {
    padding-left: 18px !important;
  }
}
</style>
export default {
    namespaced: true,
    actions: {
        async getBookingAttachmentList(context, params) {                    //资料库列表
            let { data } = await axios.post('booking/api/attachment/list', params);
            return data;
        },
        async getBookingCasInfo(context, params) {                    //CAS 商检名录列表
            let { data } = await axios.post('booking/api/cas/info', params);
            return data;
        },
        async uploadEntrustSave(context, params) {                    //上传委托书 - 新增/修改委托信息
            let { data } = await axios.post('booking/api/upload_entrust/save', params);
            return data;
        },
        async bookingQuotationNoCheck(context, params) {                    //校验报价编号接口
            let { data } = await axios.post('booking/api/quotation_no/check', params);
            return data;
        },

        async getBookingpacKingType(context, params) {                    //包装类列表
            let { data } = await axios.get('booking/api/packing_type', { params });
            return data;
        },
        async getBookingShippingTerms(context, params) {                    //运输条款
            let { data } = await axios.get('booking/api/shipping_terms', { params });
            return data;
        },
        async getBookingFeeTerms(context, params) {                    //运费条款
            let { data } = await axios.get('booking/api/fee_terms', { params });
            return data;
        },
        async getBookingLinkmanAddress(context, params) {                    //联系人地址
            let { data } = await axios.post('booking/api/address/list', params);
            return data;
        },
        async getBookingEntrustHistoryList(context, params) {                    //历史委托信息
            let { data } = await axios.post('booking/api/entrust_history/list', params);
            return data;
        },
        async getCompanyFileAndInvoice(context, params) {                    //判断公司有没有证书和开票信息
            let { data } = await axios.post('admin/api/company/file_and_invoice', params);
            return data;
        },



    }
}
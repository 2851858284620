export default {
    actions: {
        async getInquiryList(contact, params) {//发盘列表
            let { data } = await axios.post("inquiry/api/my_inquiry/list", params);
            return data;
        },
        async delInquiry(contact, params) {//删除发盘
            let { data } = await axios.get("inquiry/api/inquiry/delete", { params });
            return data;
        },
        async inquiry_updataStatus(context, params) { //更新询盘状态
            let { data } = await axios.post('inquiry/api/inquiry_status/update', params);
            return data;
        },
        async inquiry_consignorEvaluation(content, params) {
            //写评价
            let { data } = await axios.post("inquiry/api/inquiry_evaluation/save", params);
            return data;
        },
        async inquiry_searchConsignor(content, params) {//货主对货代评价
            let { data } = await axios.get("inquiry/api/inquiry_evaluation/detail", { params })
            return data
        },
        async inquiry_evaluationList(content, params) {//我的询盘评价列表
            let { data } = await axios.post("inquiry/api/inquiry_comment/list", params)
            return data
        },
        async inquiry_inquiryDetail(context, params) {
            let { data } = await axios.get('inquiry/api/inquiry/detail', { params })
            return data;
        },
    }
}
export default {
    projectConfig: {
        schoolRecruitment: {
            storeKey: 'scrStore',
            PJSource: 6,
            PJID: 'SCR',
            domainNames: {
                idc: [{
                    en: 'ailajob.com',
                    zh: 'ailajob.com'
                }],
                test: [{
                    en: 'test-job.aila.site',
                    zh: 'test-job.aila.site'
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        ymSaleTool: {
            storeKey: 'ystStore',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: 'test-job.aila.site',
                    zh: 'test-job.aila.site'
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://tool.yundida.com/'],
                test: ['https://tool.yundida.com/'],
                dev: ['https://tool.yundida.com/']
            }
        },
        siffa: {
            storeKey: 'sifStore',
            PJSource: 7,
            PJID: 'SIF',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: 'test-job.aila.site',
                    zh: 'test-job.aila.site'
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        expoMeeting: {
            storeKey: 'expStore',
            PJSource: 10,
            PJID: 'EXP',
            domainNames: {
                idc: [{
                    en: 'en.expo56.com',
                    zh: 'expo56.com'
                }, {
                    en: 'en.expo56.com',
                    zh: 'www.expo56.com'
                }, {
                    en: 'localhost:8082',
                    zh: 'localhost:8082'
                }],
                test: [{
                    en: 'testen-expo.aila.site',
                    zh: 'test-expo.aila.site'
                }, {
                    en: 'localhost:8081',
                    zh: 'localhost:8080'
                }],
                dev: [{
                    en: 'localhost:8083',
                    zh: 'localhost:8081'
                },
                {
                    en: 'localhost:8081',
                    zh: '192.168.1.2:8080'
                }
                ]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        fudaMeeting: {
            storeKey: 'fdaStore',
            PJSource: 11,
            PJID: 'FDA',
            domainNames: {
                idc: [{
                    en: 'global.tech-shipping.com',
                    zh: 'expo.tech-shipping.com'
                }],
                test: [{
                    en: 'testen-expo.aila.site',
                    zh: 'test-expo.aila.site'
                }],
                dev: [{
                    en: '192.168.1.25:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        tradeFederation: {
            storeKey: 'trfStore',
            PJSource: 12,
            PJID: 'TRF',
            domainNames: {
                idc: [{
                    en: 'global.tech-shipping.com',
                    zh: 'expo.tech-shipping.com'
                }],
                test: [{
                    en: 'testen-expo.aila.site',
                    zh: 'test-nbdlt.aila.site'
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        lySociety: {
            storeKey: 'lysStore',
            PJSource: 16,
            PJID: 'LYS',
            domainNames: {
                idc: [{
                    en: 'nita.aila.site/',
                    zh: 'nita.aila.site/'
                }],
                test: [{
                    en: 'test-job.aila.site',
                    zh: 'test-job.aila.site'
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        scbSociety: {
            storeKey: 'scbStore',
            PJSource: 13,
            PJID: 'SCB',
            domainNames: {
                idc: [{
                    en: 'global.tech-shipping.com',
                    zh: 'expo.tech-shipping.com'
                }],
                test: [{
                    en: 'testen-expo.aila.site',
                    zh: 'test-nbdlt.aila.site'
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        mpaSociety: {
            storeKey: 'mpaStore',
            PJSource: 18,
            PJID: 'MPA',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: '192.168.1.14:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        },
        ailaWRT: {
            storeKey: 'wrtStore',
            PJSource: 0,
            PJID: 'WRT',
            domainNames: {
                idc: [{
                    en: 'wrten.aila.site',
                    zh: 'wrt.aila.site'
                }],
                test: [{
                    en: 'testen-wrt.aila.site',
                    zh: 'test-wrt.aila.site'
                }],
                dev: [{
                    en: '192.168.1.30:8080',
                    zh: 'localhost:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev-mini.aila.site/service/']
            }
        },
        overseasMeeting: {
            storeKey: 'osmStore',
            PJSource: 17,
            PJID: 'OSM',
            domainNames: {
                idc: [{
                    en: 'wfa.aila.site',
                    zh: ''
                }, {
                    en: 'overseas.ailaworld.com',
                    zh: ''
                }],
                test: [{
                    en: 'test-wfa.aila.site',
                    zh: ''
                }, {
                    en: 'localhost:8080',
                    zh: ''
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: ''
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev-mini.aila.site/service/']
            }
        },
        ymPcClient: {
            storeKey: 'ympStore',
            // PJSource: 18,
            PJID: 'YMP',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: '192.168.1.10:8080'
                }]
            },
            apis: {
                idc: ['https://tool.yundida.com/ymfr/'],
                test: ['https://test2.aila.site/ymfr/'],
                dev: ['https://dev.aila.site/ymfr/']
            }
        },
        qlcSociety: {
            storeKey: 'qlcStore',
            PJSource: 14,
            PJID: 'QLC',
            domainNames: {
                idc: [{
                    en: '',
                    zh: ''
                }],
                test: [{
                    en: '',
                    zh: ''
                }],
                dev: [{
                    en: 'localhost:8080',
                    zh: '192.168.1.10:8080'
                }]
            },
            apis: {
                idc: ['https://ailaworld.com/service/'],
                test: ['https://test2.aila.site/service/'],
                dev: ['https://dev.aila.site/service/']
            }
        }
    }
}
<template>
  <div class="baseConsole console-wrapper baseConsoleProject">
    <div
      class="sidebar-container"
      :class="LOCALE"
      :style="{ background: BACCONFIG.navConfig.bgColor }"
    >
      <el-image
        v-if="BACCONFIG.navConfig.logoUrl"
        class="websiteLogo"
        fit="scale-down"
        :src="BACCONFIG.navConfig.logoUrl"
        @click="$router.push('/')"
      ></el-image>
      <div class="websiteLogo" v-else></div>
      <consoleNav :currentPath="currentPath"></consoleNav>
    </div>
    <div class="nav-contianer" :class="LOCALE">
      <div class="nav-text">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item class="parent-page">{{
            $t(labelInfo.parent)
          }}</el-breadcrumb-item>
          <el-breadcrumb-item class="child-page">{{
            $t(labelInfo.self)
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="user-avatar">
        <el-image
          :src="USER_INFO.avatar ? USER_INFO.avatar : DEFAULT_AVATAR"
          @click="goToUserInfo"
        ></el-image>
      </div>
      <div class="backHome">
        <el-button size="small" round plain @click="$router.push('/')">{{
          $t("backHome")
        }}</el-button>
      </div>
    </div>
    <div class="main-contianer" :class="LOCALE">
      <div class="app-main" v-if="!$route.meta.notUseMain">
        <div class="app-contianer">
          <router-view />
        </div>
      </div>
      <div v-else class="app-main-other">
        <div class="app-container-other">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import consoleNav from "~bac/components/common/consoleNav";
import "~bac/router/handle";
import { handleTurnToCompanyPage } from "~bac/utils";
export default {
  components: { consoleNav },
  metaInfo() {
    return {
      title: this.$t("console"),
    };
  },
  data() {
    return {
      labelInfo: {
        parent: "",
        self: "",
      },
      currentPath: "",
    };
  },
  created() {
    this.handleLabelInfo(this.$route);
  },
  methods: {
    handleLabelInfo(route) {
      this.labelInfo.parent = route.meta.parent;
      this.labelInfo.self = route.meta.label;
      this.currentPath = route.path;
    },
    goToUserInfo() {
      this.$router.push("/console/account/userInfo");
    },
  },
  watch: {
    $route: {
      deep: true,
      handler(nv) {
        this.handleLabelInfo(nv);
      },
    },
  },
};
</script>

<style scoped lang="less">
.console-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  .sidebar-container {
    width: 210px !important;
    background-color: #27292e;
    height: 100%;
    position: fixed;
    font-size: 0;
    top: 0px;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: auto;
    padding-top: 30px;
    .websiteLogo {
      width: 150px;
      height: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 20px;
      cursor: pointer;
    }
    &.en {
      width: 240px !important;
    }
  }
  .nav-contianer {
    margin-left: 210px;
    height: 50px;
    padding: 0px 10px;

    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 2000;
    &.en {
      margin-left: 240px !important;
    }
    .nav-text {
      float: left;
      height: 50px;
      line-height: 50px;
      /deep/ .el-breadcrumb {
        line-height: 50px !important;
        .parent-page {
          .el-breadcrumb__inner {
            color: #303133;
          }
        }
        .child-page {
          .el-breadcrumb__inner {
            color: #97a8be;
          }
        }
      }
    }
    .user-avatar {
      float: right;
      margin-top: 5px;
      margin-right: 10px;
      cursor: pointer;
      .el-image {
        width: 40px;
        height: 40px;
        background: #fff;
        border-radius: 4px;
        .img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .backHome {
      float: right;
      line-height: 50px;
      margin-right: 20px;
    }
  }

  .main-contianer {
    margin-left: 210px;
    position: relative;
    &.en {
      margin-left: 240px !important;
    }
    .app-main {
      width: 100%;
      position: relative;
      overflow: hidden;
      height: calc(100vh - 50px);
      background: #f2f2f2;
      .app-contianer {
        margin: 10px;
        height: calc(100vh - 70px);
        background: #fff;
        overflow: auto;
        position: relative;
      }
    }
    .app-main-other {
      width: 100%;
      height: calc(100vh - 50px);
      overflow: auto;
      background: #f2f2f2;
      padding: 10px;
      .app-container-other {
        position: relative;
        min-height: 100%;
      }
    }
  }
}
</style>
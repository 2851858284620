export default {
    namespaced: true,
    actions: {
        async getFreightList(context, params) {                    //运价查询
            let { data } = await axios.post('admin_freight/api/oss_freight/list', params);
            return data;
        },
        async getFreightHeader(context, params) {                    //运价详情头部
            let { data } = await axios.get('freight/api/lcl_freight/info', { params });
            return data;
        },
        async getnewFreightInfo(context, params) {                    //运价详情的费用方案
            let { data } = await axios.post('freight/api/new_freight/info', params);
            return data;
        },
    }
}
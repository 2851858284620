export const baseConsoleRoutes = [
    {
        path: '/console/account/userInfo',     // 个人信息
        name: 'account-userInfo',
        component: resolve => require(['~bac/views/account/userInfo'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '1',
            nameId: '1-1',
            auth: 'mbc:usi',
            platform: ['ALL']
        }
    },
    {
        path: '/console/account/security',     //安全设置
        name: 'account-security',
        component: resolve => require(['~bac/views/account/security'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '1',
            nameId: '1-2',
            auth: 'mbc:ses',
            platform: ['ALL']
        }
    },
    {
        path: '/console/account/realName',     //实名认证
        name: 'account-realName',
        component: resolve => require(['~bac/views/account/realName'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '1',
            nameId: '1-3',
            auth: '',
            hiddenInEnLocale: true,
            platform: ['EXP']
        }
    },
    {
        path: '/console/company/basic',           //企业信息
        name: 'company-basic',
        component: resolve => require(['~bac/views/company/basic'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-1',
            auth: 'cos:coi',
            platform: ['ALL']
        }
    },
    {
        path: '/console/company/picture',            //企业图片
        name: 'company-picture',
        component: resolve => require(['~bac/views/company/picture'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-2',
            auth: 'coi:img',
            notShow: 'OSM',
            platform: ['ALL']
        }
    },
    {
        path: '/console/company/certificateList',            //企业证书
        name: 'company-certificateList',
        component: resolve => require(['~bac/views/company/certificateList'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-3',
            auth: 'coi:asc',
            notShow: 'OSM',
            platform: ['ALL']
        }
    },
 
    {
        path: '/console/company/video',            //企业视频
        name: 'company-video',
        component: resolve => require(['~bac/views/company/video'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-4',
            auth: 'coi:ved',
            notShow: 'OSM',
            platform: ['ALL']
        }
    },
    {
        path: '/console/company/accountRole',             //账号管理
        name: 'company-accountRole',
        component: resolve => require(['~bac/views/company/accountRole'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-5',
            auth: 'coi:acm',
            platform: ['TRF', 'SIF', 'LYS', 'SCB','QLC']
        }
    },
    {
        path: '/console/company/YMPaccountRole',             //账号管理
        name: 'company-YMPaccountRole',
        component: resolve => require(['~bac/views/company/YMPaccountRole'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-10',
            auth: 'coi:acm',
            platform: ['YMP']
        }
    },
    {
        path: '/console/company/business',              //企业业务
        name: 'company-business',
        component: resolve => require(['~bac/views/company/business'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-6',
            auth: 'coi:cbs',
            notShow: 'OSM',
            platform: ['ALL']
        }
    },
    {
        path: '/console/company/attention',               //关注点赞
        name: 'company-attention',
        component: resolve => require(['~bac/views/company/attention'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-7',
            auth: '',
            platform: ['COP']
        }
    },
    {
        path: '/console/company/favorite',               //收藏夹
        name: 'company-favorite',
        component: resolve => require(['~bac/views/company/favorite'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-8',
            auth: '',
            platform: ['TRF', 'SIF', 'LYS', 'SCB', 'QLC', 'MPA']
        }
    },
    // 会议管理
    // {
    //     path: '/console/conference/myConference',                //我的会议
    //     name: 'conference-myConference',
    //     component: resolve => require(['~bac/views/conference/myConference'], resolve),
    //     meta: {
    //         layout: 'baseConsole',
    //         parentId: '3',
    //         nameId: '3-1',
    //         auth: '',
    //         platform: ['COP']
    //     }
    // },
    {
        path: '/console/conference/caseCard',                 //名片夹
        name: 'conference-caseCard',
        component: resolve => require(['~bac/views/conference/caseCard'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-3',
            //auth: 'mem:cad',
            platform: ['EXP', 'FDA', 'OSM']
        }
    },
    {
        path: '/console/conference/caseCard/admin',                 //名片管理
        name: 'conference-caseCard',
        component: resolve => require(['~bac/views/conference/caseCard/admin'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-4',
            //auth: 'mem:cai',
            notUseMain: true,
            platform: ['EXP', 'FDA', 'OSM']
        }
    },
    {
        path: '/console/conference/attendance',                 //参会人员管理
        name: 'conference-attendance',
        component: resolve => require(['~bac/views/conference/attendance/index'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-5',
            auth: 'mem:jmp',
            platform: ['EXP', 'FDA', 'OSM']
        }
    },
    {
        path: '/console/conference/live/guest',                 //嘉宾/主持人设置
        name: 'conference-guest',
        component: resolve => require(['~bac/views/conference/live/guest'], resolve),
        meta: {
            layout: 'baseConsole',
            hiddenInMenu: true,
            notUseMain: true,
            parentId: '3',
            nameId: '3-6',
            auth: 'mem:jmp',
            platform: ['EXP', 'FDA']
        }
    },
    {
        path: '/console/conference/live/index',                 //直播管理列表
        name: 'conference-guest',
        component: resolve => require(['~bac/views/conference/live/index'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-7',
            auth: 'mem:lim',
            platform: ['EXP', 'FDA']
        }
    },
    {
        path: '/console/conference/live/live_set',                 //直播  创建&修改
        name: 'conference-live_set',
        component: resolve => require(['~bac/views/conference/live/live_set'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-8',
            auth: 'mem:lim:add',
            platform: ['EXP', 'FDA'],
            hiddenInMenu: true,
            notUseMain: true,
        }
    },
    {
        path: '/console/conference/boothManage',                 //展台管理
        name: 'conference-mySponsor',
        component: resolve => require(['~bac/views/conference/boothManage'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-2',
            auth: 'mem:bom',
            notUseMain: true,
            platform: ['EXP', 'FDA'],

        }
    },
    {
        path: '/console/conference/boothPreview',                 //展台预览
        name: 'conference-boothPreview',
        component: resolve => require(['~bac/views/conference/boothManage/preview'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-10',
            notUseMain: true,
            hiddenInMenu: true,
            platform: ['EXP', 'FDA'],

        }
    },
    {
        path: '/console/conference/redEnvelopeList',                 //业务红包管理
        name: 'conference-redEnvelopeAdmin',
        component: resolve => require(['~bac/views/conference/redEnvelopeAdmin/list'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-11',
            auth: 'mem:yrm',
            notUseMain: true,
            platform: ['EXP'],
        }
    },
    {
        path: '/console/conference/receiveBusiness',                 //业务红包领取
        name: 'conference-receiveBusiness',
        component: resolve => require(['~bac/views/conference/redEnvelopeAdmin/receiveBusiness'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-17',
            auth: 'mem:yrm',
            hiddenInMenu: true,
            notUseMain: true,
            platform: ['EXP'],
        }
    },
    {
        path: '/console/conference/cash',                 //现金红包管理
        name: 'conference-cash',
        component: resolve => require(['~bac/views/conference/redEnvelopeAdmin/cash'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-12',
            auth: 'mem:xrm',
            notUseMain: true,
            platform: ['EXP'],
        }
    },

    {
        path: '/console/conference/cash_detail',                 //现金红包 领取记录
        name: 'conference-cash_detail',
        component: resolve => require(['~bac/views/conference/redEnvelopeAdmin/cash_detail'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-18',
            auth: '',
            notUseMain: true,
            hiddenInMenu: true,
            platform: ['EXP'],

        }
    },
    {
        path: '/console/conference/myRedEnvelope',                 //我的红包
        name: 'conference-myRedEnvelope',
        component: resolve => require(['~bac/views/conference/redEnvelopeAdmin/myRedEnvelope'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-13',
            auth: 'mem:mrb',
            platform: ['EXP'],
        }
    },
    {
        path: '/console/conference/myUsedList',                 //我的红包-业务红包使用记录
        name: 'conference-myUsedList',
        component: resolve => require(['~bac/views/conference/redEnvelopeAdmin/myUsedList'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-13',
            hiddenInMenu: true,
            auth: '',
            platform: ['EXP'],
        }
    },
    {
        path: '/console/conference/accountRecords',                 // 账户记录
        name: 'conference-accountRecords',
        component: resolve => require(['~bac/views/conference/redEnvelopeAdmin/accountRecords'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-14',
            auth: 'mem:acr',
            platform: ['EXP'],

        }
    },
    {
        path: '/console/conference/myReserved',                 //我的洽谈
        name: 'conference-myReserved',
        component: resolve => require(['~bac/views/conference/myReserved'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-9',
            auth: 'mem:myi',
            platform: ['EXP', 'FDA', 'SIF', 'LYS', 'OSM', 'QLC'],
        }
    },
    {
        path: '/console/conference/record',                 //洽谈记录
        name: 'conference-record',
        component: resolve => require(['~bac/views/conference/record/index'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            hiddenInMenu: true,
            nameId: '3-16',
            auth: '',
            platform: ['EXP', 'FDA']
        }
    },
    {
        path: '/console/conference/myCollection',                 //我的收藏
        name: 'conference-myCollection',
        component: resolve => require(['~bac/views/conference/myCollection'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-15',
            // auth: 'mem:xrm',
            // notUseMain: true,
            platform: ['EXP', 'FDA'],
        }
    },
    {
        path: '/console/conference/negotiationSetting',                 //洽谈设置
        name: 'conference-negotiationSetting',
        component: resolve => require(['~bac/views/conference/negotiationSettings'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '3',
            nameId: '3-19',
            // auth: 'mem:xrm',
            // notUseMain: true,
            platform: ['OSM'],
        }
    },
    {
        path: '/console/talentCenter/positionAdmin',                 // 人才中心-职位管理
        name: 'talentCenter-positionAdmin',
        component: resolve => require(['~bac/views/talentCenter/positionAdmin/index'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '4',
            nameId: '4-1',
            auth: 'tac:jom',//
            platform: ['TRF', 'SIF', 'LYS', 'SCB', 'QLC', 'MPA'],
        }
    },
    {
        path: '/console/myApply/membershipApply',                 // 我的申请-入会申请
        name: 'talentCenter-membershipApply',
        component: resolve => require(['~bac/views/myApply/membershipApply/index'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '5',
            nameId: '5-1',
            auth: 'mal:jma',
            platform: ['SIF', 'TRF', 'LYS', 'SCB', 'QLC', 'MPA'],
        }
    },
    // {
    //     path: '/console/myApply/membershipApply/membershipApply',                 // 我的申请-入会申请（适用于物贸联合会）
    //     name: 'talentCenter-membershipApply_2',
    //     component: resolve => require(['~bac/views/myApply/membershipApply/membershipApply'], resolve),
    //     meta: {
    //         layout: 'baseConsole',
    //         parentId: '5',
    //         nameId: '5-3',
    //         auth: '',
    //         platform: ['TRF'],
    //     }
    // },
    {
        path: '/console/myApply/activityApply',                 // 我的申请-活动申请
        name: 'talentCenter-activityApply',
        component: resolve => require(['~bac/views/myApply/activityApply/index'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '5',
            nameId: '5-2',
            auth: 'mal:aca',
            platform: ['TRF', 'SIF', 'LYS', 'SCB', 'QLC', 'MPA'],
        }
    },
    {
        path: '/console/conference/myReserved',                 //我的预约
        name: 'conference-myReserved-1v1',
        component: resolve => require(['~bac/views/conference/myReserved'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '6',
            nameId: '6-1',
            auth: '',
            platform: ['SIF', 'LYS', 'QLC'],
        }
    },
    {
        path: '/console/myEntrust/list',                 //玉蟒  我的委托列表
        name: 'myEntrustList',
        component: resolve => require(['~bac/views/dealManagement/myEntrust/list'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '7',
            nameId: '7-2',
            auth: '',
            platform: ['YMP'],
        }
    },
    {
        path: '/console/myEntrust/details',                 //玉蟒  我的委托详情
        name: 'myEntrustDetails',
        component: resolve => require(['~bac/views/dealManagement/myEntrust/details'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '7',
            nameId: '7-3',
            auth: '',
            hiddenInMenu: true,
            platform: ['YMP'],
        }
    },
    {
        path: '/console/myEntrust/detailsOrdered',                 //玉蟒  我的委托详情（已成单）
        name: 'detailsOrdered',
        component: resolve => require(['~bac/views/dealManagement/myEntrust/detailsOrdered'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '7',
            nameId: '7-6',
            auth: '',
            hiddenInMenu: true,
            notUseMain: true,
            platform: ['YMP'],
        }
    },
    {
        path: '/console/dataBank',                 //玉漭  资料库
        name: 'myEntrustDetails',
        component: resolve => require(['~bac/views/dealManagement/dataBank/index'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '7',
            nameId: '7-4',
            auth: '',
            hiddenInMenu: false,
            platform: ['YMP'],
        }
    },
    {
        path: '/console/adress',                 //玉漭  地址管理
        name: 'myEntrustDetails',
        component: resolve => require(['~bac/views/dealManagement/adress/index'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '7',
            nameId: '7-5',
            auth: '',
            hiddenInMenu: false,
            platform: ['YMP'],
        }
    },
    {
        path: '/console/company/invoice',           
        name: 'company-invoice',
        component: resolve => require(['~bac/views/company/invoice'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '2',
            nameId: '2-9',
            auth: 'cos:mak',
            hiddenInMenu: false,
            platform: ['YMP']
        }
    },
    {
        path: '/console/directOverseas/myInquiry',                 //我的发盘
        name: 'directOverseas_myInquiry',
        component: resolve => require(['~bac/views/directOverseas/myInquiry'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '8',
            nameId: '8-1',
            platform: ['SIF'],
        }
    },
    {
        path: '/console/directOverseas/inquiryDetail',                 //我的发盘--详情
        name: 'directOverseas_inquiryDetail',
        component: resolve => require(['~bac/views/directOverseas/inquiryDetail'], resolve),
        meta: {
            layout: 'baseConsole',
            parentId: '8',
            nameId: '8-2',
            hiddenInMenu: true,
            notUseMain: true,
            platform: ['SIF'],
        }
    },
]
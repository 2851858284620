export const constName = {
    schoolRecruitment: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    siffa: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    expoMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    fudaMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    ailaWRT: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    tradeFederation: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    lySociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    scbSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    mpaSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    overseasMeeting: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    ymPcClient: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    },
    qlcSociety: {
        userId: 'userId',
        resetPasswordUserId: 'resetPasswordUserId'
    }
}
export default {
  computed: {
    MEETING_ID() {
      return this.$store.state.meeting_id;
    },
    ENROLL_INFO() {
      return this.$store.state.baseStore.enrollInfo;//报名信息
    },
    MEETING_INFO() {
      return this.$store.state.baseStore.meetingInfo;//会议信息
    },
    TALK_DURATON(){
      return this.$store.state.talkDuration;//洽谈时长
    }
  },
  methods: {
    handleUserMeetingStatus(toDos = {
      //检查用户会议状态：   notLogin:未登录；isLogin:用户已登录（不进行会议状态验证); notCompany:未绑定公司;notEnroll:人员未报名;enrollWait:人员待审核;enrollFail:人员审核失败；
      //enrollComplete:人员报名成功;notExhibitor:非参展商企业
      notLogin: () => {
        this.$router.push("/sign");
      }
    }) {
      if (!this.IS_LOGIN) {  //用户未登录
        toDos.notLogin();
        return;
      }
      if (this.IS_LOGIN) {    //用户登录
        if (toDos.isLogin) {
          toDos.isLogin();
          return;
        }
      }
      if (!this.USER_INFO.company_id) {   //用户不存在公司
        if (toDos.notCompany) {
          toDos.notCompany();
          return;
        }
      }
      if (this.USER_INFO && !this.ENROLL_INFO.is_user_enroll) {     //用户未报名会议
        if (toDos.notEnroll) {
          toDos.notEnroll();
          return;
        }
      }
      if (this.USER_INFO && this.ENROLL_INFO.is_user_enroll && !this.ENROLL_INFO.audit_status) {    //用户待审核
        if (toDos.enrollWait) {
          toDos.enrollWait();
          return;
        }
      }
      if (this.USER_INFO && this.ENROLL_INFO.is_user_enroll && this.ENROLL_INFO.audit_status == 2) {    //用户审核拒绝
        if (toDos.enrollFail) {
          toDos.enrollFail();
          return;
        }
      }
      if (this.USER_INFO && this.ENROLL_INFO.is_user_enroll && this.ENROLL_INFO.audit_status == 1) {    //用户报名完成
        if (toDos.notExhibitor) {
          if (this.ENROLL_INFO.apply_type != 1 && this.ENROLL_INFO.apply_type != 2) {             //非参展商企业
            toDos.notExhibitor()
            return;
          }
        }
        if (toDos.isExhibitor) {                                                                  //参展商用户
          if (this.ENROLL_INFO.apply_type == 1 || this.ENROLL_INFO.apply_type == 2) {
            toDos.isExhibitor();
            return;
          }
        }
        if (toDos.enrollComplete) {                                                               //报名完成
          toDos.enrollComplete();
          return;
        }
      }
    },
  }
}


export default {
    actions: {

        async getBookingEntrustList(context, params) {                    //我的委托，列表
            let { data } = await axios.post('booking/api/entrust/list', params);
            return data;
        },
        async getEntrustDetails(context, params) {                    //我的委托详情
            let { data } = await axios.get('booking/api/entrust/details', { params });
            return data;
        },
        async getEntrustFeeList(context, params) {                    //海运费明细
            let { data } = await axios.post('booking/api/entrust_fee/list', params);
            return data;
        },
        async updateDataBank(context, params) {                    //资料库批量删除
            let { data } = await axios.post('booking/api/attachment/deleted', params);
            return data;
        },
        async saveDataBank(context, params) {                    //资料库增改
            let { data } = await axios.post('booking/api/attachment/upload', params);
            return data;
        },
        async getBookingAddressList(context, params) {                    //地址列表
            let { data } = await axios.post('booking/api/address/list', params);
            return data;
        },
        async updateBookingAddress(context, params) {                    //更新地址
            let { data } = await axios.post('booking/api/address/save', params);
            return data;
        },
        async getBookingbusinessOrderBasicInfo(context, params) {                    //委托详情 - 已成单 - 基本信息和订舱信息
            let { data } = await axios.get('booking/api/business_order_basic/info', { params });
            return data;
        },
        async getBookingbusinessOrderInfo(context, params) {                    //委托详情 - 已成单 - 费用信息
            let { data } = await axios.get('booking/api/business_order_fee/info', { params });
            return data;
        },
        async getBookingbusinessOrderFilesInfo(context, params) {                   //委托详情 - 已成单 - 文件信息
            let { data } = await axios.get('booking/api/business_order_files/info', { params });
            return data;
        },
        async bookingEntrustFilesUpload(context, params) {                   //委托详情 - 已成单 - 文件上传
            let { data } = await axios.post('booking/api/entrust_files/upload', params);
            return data;
        },
        async getBookingBusinessOrderNodeinfo(context, params) {                   //委托详情 - 已成单 - 订单节点信息查询
            let { data } = await axios.get('admin_booking/api/business_order_node/info', { params });
            return data;
        },
        async saveBookingBusinessOrderNodeinfo(context, params) {                   //委托详情 - 已成单 //修改订单节点信息
            let { data } = await axios.post('admin_booking/api/business_order_node/save', params);
            return data;
        },
    }
}
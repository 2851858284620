
const i18nConfig = {
    schoolRecruitment: {
        locale: 'zh',
        useI18n: true,
        i18nKey: 'scrI18n',
        i18nBelong: '3,999',
        i18nEnCatalog: 'src/projects/schoolRecruitment/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/schoolRecruitment/locale/zh/i18nWord.js'
    },
    ymSaleTool: {
        locale: 'zh',
        useI18n: true,
        i18nKey: 'ystI18n',
        i18nBelong: '4,999',
        i18nEnCatalog: 'src/projects/schoolRecruitment/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/schoolRecruitment/locale/zh/i18nWord.js'
    },
    siffa: {
        locale: 'zh',
        useI18n: true,
        i18nKey: 'sifI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/siffa/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/siffa/locale/zh/i18nWord.js'
    },
    expoMeeting: {
        useI18n: true,
        i18nKey: 'expI18n',
        i18nBelong: '6,999',
        i18nEnCatalog: 'src/projects/expoMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/expoMeeting/locale/zh/i18nWord.js'
    },
    fudaMeeting: {
        useI18n: true,
        i18nKey: 'fdaI18n',
        i18nBelong: '6,999',
        i18nEnCatalog: 'src/projects/fudaMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/fudaMeeting/locale/zh/i18nWord.js'
    },
    ailaWRT: {
        useI18n: true,
        i18nKey: 'wrtI18n',
        i18nBelong: '999',
        i18nEnCatalog: 'src/projects/ailaWRT/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/ailaWRT/locale/zh/i18nWord.js'
    },
    tradeFederation:{
        locale: 'zh',
        useI18n: true,
        i18nKey: 'trfI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/tradeFederation/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/tradeFederation/locale/zh/i18nWord.js'
    },
    lySociety:{
        useI18n: true,
        i18nKey: 'lysI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/lySociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/lySociety/locale/zh/i18nWord.js'
    },
    scbSociety:{
        useI18n: true,
        i18nKey: 'scbI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/scbSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/scbSociety/locale/zh/i18nWord.js'
    },
    mpaSociety:{
        useI18n: true,
        i18nKey: 'mpaI18n',
        i18nBelong: '5,999',
        i18nEnCatalog: 'src/projects/mpaSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/mpaSociety/locale/zh/i18nWord.js'
    },
    overseasMeeting:{
        useI18n: true,
        i18nKey: 'osmI18n',
        i18nBelong: '999',
        i18nEnCatalog: 'src/projects/overseasMeeting/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/overseasMeeting/locale/zh/i18nWord.js'
    },
    ymPcClient:{
        useI18n: true,
        i18nKey: 'ympI18n',
        i18nBelong: '999',
        i18nEnCatalog: 'src/projects/ymPcClient/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/ymPcClient/locale/zh/i18nWord.js'
    },
    qlcSociety:{
        useI18n: true,
        i18nKey: 'qlcI18n',
        i18nBelong: '999',
        i18nEnCatalog: 'src/projects/qlcSociety/locale/en/i18nWord.js',
        i18nZhCatalog: 'src/projects/qlcSociety/locale/zh/i18nWord.js'
    },
};
module.exports = i18nConfig;
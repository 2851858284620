import baseConfig from '~/baseConfig/index.js'

export default {
    actions: {
        async getBoothCompanyInfo(context, params) {                    //获取公司信息
            let { data } = await axios.post('meeting/api/booth/company_info',  params );
            return data;
        },
        async handleCollection(context, params) {                    //收藏
            let { data } = await axios.post('company/api/follow',  params );
            return data;
        },
        async handleCollectionNew(context, params) {                    //收藏
            let { data } = await axios.post('expo/api/meeting_booth/like',  params );
            return data;
        },
        async checkFollow(context, params) {                    //检查收藏
            let { data } = await axios.get('expo/api/meeting_booth_like/check',  {params} );
            return data;
        },
        async getBoothAgaen(context, params) {                    //查询展台议程
            let { data } = await axios.get('meeting/api/agenda_info/booth_id',  {params} );
            return data;
        },
        async getBoothAbout(context, params) {                    //查询展台议程
            let { data } = await axios.get('expo/api/booth/user_and_video_info',  {params} );
            return data;
        },
        async checkChatStatus(context, params) {                    //邀约状态检查
            let { data } = await axios.post('expo/api/daily_chat_status/check',  params );
            return data;
        },
        async sendDailyEve(context, params) {                    //日常1v1预约
            let { data } = await axios.post('expo/api/expo_daily_chat/invite',  params );
            return data;
        },
        async getBmStatus(context, params) {                    //报名状态
            let { data } = await axios.get('nsf/api/check/is_enroll',  {params} );
            return data;
        },
        async getBoothRedDetail(context, params) {                    //获取展台红包情况
            let { data } = await axios.post('envelope/api/get_booth_red_envelope',  params);
            return data;
        },
        async getLeftRedNumber(context, params) {                    //获取红包剩余个数
            let { data } = await axios.post('envelope/api/booth_red_envelope/left_num',  params);
            return data;
        },
        async getRed(context, params) {                    //领取红包
            let { data } = await axios.post('envelope/api/booth_red_envelope/receive',  params);
            return data;
        },
        async getUserRedOnce(context, params) {                    //查询用户红包次数
            let { data } = await axios.post('envelope/api/user_receive_envelope_num',  params);
            return data;
        },
        async getMiniEcode(context, params) {                    //获取小程序二维码
            let { data } = await axios.post('wechat/api/generate_rq_code',  params);
            return data;
        },
        async getIsOnline(context, params) {//获取当前负责人是否在线
            let { data } = await axios.post("job/api/check/status", params );
            return data;
          },
        async getShortAdress(context, params) {//获取短地址
            let { data } = await axios.get("base/api/address/short", {params} );
            return data;
          },

    }
}
import { COUNTRYLIST } from './countryList'
import { CITYLIST } from './cityList'
import { AREACODELIST } from './areaCode'
function sortListEn(list) {
    return list.sort((param1, param2) => {
        return (param1.name_en + "").localeCompare(param2.name_en + "")// 英文排序
    });
}

function sortListZh(list) {
    let array = list.concat([])
    let resultArray = array.filter(it => it.id != 1).sort((param1, param2) => {
        return param1.name_zh.localeCompare(param2.name_zh, "zh");// 中文排序
    });
    resultArray.unshift(array[0])
    return resultArray
}
//根据语言获取国家列表
export function getCountryList(locale = 'en') {
    let _countryList = [].concat(COUNTRYLIST)
    if (locale == 'zh') {
        let arr = sortListZh(_countryList)
        arr.forEach(item => {
            item.name = item.name_zh;
        });
        return arr;
    } else {
        let arr = sortListEn(_countryList)
        arr.forEach(item => {
            item.name = item.name_en;
        });
        return arr;
    }
};

//获取城市列表
export function getCityListByCountryId(countryId, locale = 'en') {
    let currentList = CITYLIST.filter(item => {
        return item.country_id == countryId
    })
    if (locale == 'zh') {
        currentList.forEach(item => {
            item.name = item.name_zh;
            item.id = parseInt(item.id);
        })
        return currentList
    } else {
        currentList.forEach(item => {
            item.name = item.name_en;
            item.id = parseInt(item.id);
        })
        return currentList
    }
}

//获取区号列表(按语言排序)
export function getAreaCodeListByLocale(locale = 'en') {
    const array = AREACODELIST.concat([]);
    let resultArray;
    if (locale == 'en') {
        resultArray = array.sort((param1, param2) => {
            return (param1.name_en + "").localeCompare(param2.name_en + "")// 英文排序
        });
    } else {
        resultArray = array.filter(it => it.area_code != "+86").sort((param1, param2) => {
            return param1.name_zh.localeCompare(param2.name_zh, "zh");// 中文排序
        });
        resultArray.unshift(array[0])
    }
    resultArray = resultArray.map(item => {
        return {
            area_code: item.area_code,
            country: item.id
        };
    }).filter(item => {
        return item.area_code
    })
    return resultArray;
}

let _areaCodeList = AREACODELIST.map(item => {
    return {
        area_code: item.area_code,
        country: item.id
    };
}).filter(item => {
    return item.area_code
})
//区号列表(无ID)
export let areaCodeList = _areaCodeList;

//根据国家id获取区号
export function getAreaCodeByCountry(countryId) {
    if (!countryId) return []
    let areaCode = '';
    if (countryId) {
        let country = AREACODELIST.find(item => {
            return item.id == countryId;
        });
        if (country) areaCode = country.area_code
    };
    return areaCode;
};

//根据国家字段获取id
export function getCountryIdByName(name, locale = 'en') {
    if (!name) return '';
    let id = '';
    if (name) {
        let country = COUNTRYLIST.find(item => {
            return item[`name_${locale}`] == name;
        });
        if (country) id = country.id
    };
    return id
}

//根据国家id获取国家name
export function getCountryNameById(id, locale = 'en') {
    if (!id) return '-';
    let current = COUNTRYLIST.find(item => item.id == id);
    if (current) {
        return current[`name_${locale}`]
    } else {
        return '-'
    }
}
//根据国家id获取区号地区
export function getAreaCodeAddressNameById(id, locale = 'en') {
    if (!id) return '-';
    let current = AREACODELIST.find(item => item.id == id);
    if (current) {
        return current[`name_${locale}`]
    } else {
        return '-'
    }
}
//根据国家id获取区号Title  格式为:国家   区号
export function getAreaCodeTitleNameById(id, locale = 'en') {
    if (!id) return '-';
    let current = AREACODELIST.find(item => item.id == id);
    if (current) {
        return current[`name_${locale}`] + '  ' + current.area_code
    } else {
        return '-'
    }
}

//根据国家id获取国旗
export function getCounrtyFlagById(id) {
    let flagStr = 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/flag/null.png'
    if (!id) return flagStr;
    let current = COUNTRYLIST.find(item => item.id == id);
    if (current) {
        return `https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/flag/${current.code}.png`
    }
}

//根据城市id获取城市name
export function getCityNameById(id, locale = 'en') {
    if (!id) return '-';
    let current = CITYLIST.find(item => item.id == id);
    if (current) {
        return current[`name_${locale}`]
    } else {
        return '-'
    }
}


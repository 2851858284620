import { commonEnumFormat } from "../utils/enumValue";

import { oneToOneDailyStatus } from '../utils/enumValue';
export const oneToOneDailyStatusFormat = commonEnumFormat(oneToOneDailyStatus)
import { levelRequire } from '../utils/enumValue'                 //学历要求
export const levelRequireFormat = commonEnumFormat(levelRequire)

import { positionTypeList } from '../utils/enumValue'
export const positionTypeListFormat = commonEnumFormat(positionTypeList)

//工作经验
import { experience } from '../utils/enumValue'
export const experienceFormat = commonEnumFormat(experience)
import { businessList } from '../utils/enumValue'
export function getBusinessObject(value, isSelf = false) {      //获取业务对象
  if (!value) return;
  let returnObj = {
    'Convertional': [],
    'Particular Cargo': [],
    'Services': [],
    'Other convertional': [],
    'Dangerous Cargo': []
  }
  let ids;
  if (isSelf) {
    ids = businessList.map(item => {
      return item.id;
    })
  } else {
    ids = value.split(',')
  }
  let filters = [];
  ids.forEach(item => {
    let current = businessList.find(e => {
      return e.id == item
    });
    filters.push(current)
  });
  Object.keys(returnObj).forEach(item => {
    let currentArr = filters.filter(e => {
      return e.name_en == item;
    });
    returnObj[item] = currentArr;
  });
  return returnObj
}
export function commonEnumFormat(datalist, label = 'label') { //公共枚举过滤器，用于生成不同的过滤器

    return function (input, locale = 'en') {

        if (!input) {
            return '-'
        }
        let current = datalist.find(item => {
            return item.value == input
        });
        if (current) {
            return current[`${label}_${locale}`]
        } else {

            return '-'
        }
    }
}


export function textFormat(input) {
    //普通文本过滤器
    try {
        if (
            input === null ||
            input === undefined ||
            input === "" ||
            input === 'null' ||
            input === 'undefined'
        ) {
            return "-";
        }
        return input;
    } catch (err) {
        return input;
    }
}

export function numberFormat(input, format) {
    //普通数字过滤器
    try {
        input = parseFloat(input);
        if (isNaN(input)) {
            return "-";
        } else {
            if (format) {
                return parseFloat((input * 1).toFixed(format))
            } else {
                return input
            }
        }
    } catch (err) {
        return "-";
    }
}
export function secondFormat(input, format) {
    //时间戳过滤器
    if (input === "" || input === null || input === undefined || input === 0) {
        return "-";
    }
    format = format || "LLLL";
    const formatKey = {
        YM: "YYYY-MM",
        L: "MM-DD",
        L1: 'MM/DD',
        LL: "YYYY-MM-DD",
        LLL: "YYYY-MM-DD HH:mm",
        LLLL: "YYYY-MM-DD HH:mm:ss",
        LLLLSS: "YYYY-MM-DD HH:mm:ss SS",
        T: "HH:mm",
        TT: "HH:mm:ss",
        LT: "MM-DD HH:mm",
        DD: "DD",
        MM: "MM",
    };
    format = formatKey[format];
    const date = moment(input * 1000);
    if (date.isValid()) {
        input = date.format(format);
    } else {
        input = "-";
    }
    return input;
}

export function lineFormat(input, format = "") {
    //用于优先显示规则不显示 -
    if (input == "-") {
        return format;
    } else {
        return input;
    }
}
import { getCountryNameById } from "~/baseUtils/AddressConfig"; //根据国家ID匹配国家名称
export const countryFormat = getCountryNameById;

export function countryCityFormat(countryId, region_en, region_zh, locale = 'en') {
    //国家城市显示规则
    let str = ''
    let country = lineFormat(countryFormat(countryId, locale));
    let city = lineFormat(priorFormat(region_en, region_zh, locale));
    if (locale == 'en') {
        if (city && country) {
            str = `${city} , ${country}`
        } else if (city && !country) {
            str = `${city}`
        } else {
            str = `${country}`
        }
    } else {
        if (city && country) {
            str = `${country} - ${city}`
        } else if (city && !country) {
            str = `${city}`
        } else {
            str = `${country}`
        }
    }
    return str;
}

export function priorFormat(en, zh, locale = 'en') {
    //优先展示逻辑
    if (locale == 'zh') {
        let str = textFormat(zh) == '-' ? textFormat(en) : textFormat(zh);
        return str;
    } else {
        let str = textFormat(en) == '-' ? textFormat(zh) : textFormat(en);
        return str;
    }
}
import { Industry } from "~/baseUtils/enumValue";

export const phoneFormat = function (phone, areaCode) {
    //手机号显示规则
    if (!phone || phone == '-') { phone = "" };
    if (!areaCode || areaCode == '-') { areaCode = "" }
    let phoneStr = String(phone)
    let areaCodeStr = String(areaCode)
    if (phoneStr.trim() && areaCodeStr.trim()) {
        return areaCodeStr + " " + phoneStr
    }
    if (phoneStr.trim() && !areaCodeStr.trim()) {
        return phoneStr
    }
    if (!phoneStr.trim() && areaCodeStr.trim()) {
        return '-'
    }
    if (!phoneStr.trim() && !areaCodeStr.trim()) {
        return '-'
    }
}

export function industryFormat(value, locale = 'en') {
    let str = "-"
    Industry.forEach((eee, vvv) => {
        eee.children.forEach((ee, vv) => {
            if (ee.value == value) {
                if (locale == "en") {
                    str = `${Industry[vvv].label_en} - ${ee.label_en}`;
                } else {
                    str = `${Industry[vvv].label_zh} - ${ee.label_zh}`;
                }
            }
        });
    });
    return str
}
// 时间日期处理时间函数
export function formatDate(sortedDateList) {
    var arr = [];
    sortedDateList.forEach(function (item, i) {
        var tmpDate = new Date(item.start_time * 1000);
        let timer = moment(tmpDate).format('YYYY-MM-DD')
        // 首先取第一个时间戳（也是最小的时间戳）
        if (i === 0) {
            var tmpObj = {};
            tmpObj.date = timer; // 时间戳对应的日期
            tmpObj.dataList = []; // 存储相同时间戳日期的数组
            tmpObj.dataList.push(item);
            arr.push(tmpObj);
        } else {
            // 判断两个时间戳对应的日期是否相等，相等就加进去，不相等就另开辟新的时间戳日期
            if (arr[arr.length - 1]["date"] === timer) {
                arr[arr.length - 1]["dataList"].push(item);
            } else {
                var tmpObj = {};
                tmpObj.date = timer;
                tmpObj.dataList = [];
                tmpObj.dataList.push(item);
                arr.push(tmpObj);
            }
        }
    });
    return arr;
}
export function formatSeconds(value) {
    //处理视频时长
    var theTime = parseInt(value); // 秒
    var middle = 0; // 分
    var hour = 0; // 小时

    if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
            hour = parseInt(middle / 60);
            middle = parseInt(middle % 60);
        }
    }
    var result = "" + parseInt(theTime) + "";
    result =
        "" +
        (parseInt(middle) < 10 ? "0" + parseInt(middle) : parseInt(middle)) +
        ":" +
        result;
    result =
        "" +
        (parseInt(hour) < 10 ? "0" + parseInt(hour) : parseInt(hour)) +
        ":" +
        result;
    return result;
}

//获取文件尺寸
import { getFileSize } from '@/baseUtils'
export const getFilesSize = getFileSize

//获取文件名
import { getFileName } from '@/baseUtils'
export const getFilesName = getFileName

import { enclosureType } from "~/baseUtils/enumValue";
export function getenclosureImg(type) {
    //根据询盘附件后缀获取文件图标
    let value = "";
    value = enclosureType.find(
        item => item.types.indexOf(type.toLowerCase()) != -1
    ).img;
    return value;
}

import { staffsizeList } from "@/baseUtils/enumValue";
export const staffSizeFormat = commonEnumFormat(staffsizeList);

export function enclosureImgbyTypeFormat(type) {
    //根据询盘附件类型获取文件图标
    let value = "";
    value = enclosureType.find(item => item.value == type).img;
    return value;
}

export function getenclosureType(type) {
    //根据询盘附件后缀获取文件类型
    let value = "";
    value = enclosureType.find(
        item => item.types.indexOf(type.toLowerCase()) != -1
    ).value;
    return value;
}
export function getFileType(filePath) {
    //获取文件后缀名
    let startIndex = filePath.lastIndexOf(".");
    if (startIndex != -1)
        return filePath.substring(startIndex + 1, filePath.length).toUpperCase();
    else return "";
}

import { getAreaCodeAddressNameById } from '~/baseUtils/AddressConfig'
export const areacodeAddressFormat = getAreaCodeAddressNameById;

import { getAreaCodeTitleNameById } from '~/baseUtils/AddressConfig'
export const areacodeTitleFormat = getAreaCodeTitleNameById;

import { certificateList } from "~/baseUtils/enumValue"; //协会类型
function commonEnumFormatCertificate(datalist, label = 'label') {
    return function (input, name, locale = 'en') {
        if (!input) {
            return '-'
        }
        let current = datalist.find(item => {
            return item.value == input
        });
        if (current) {
            return current[`${label}_${locale}`]
        } else {
            if (input == 12) {
                return name
            } else {
                return '-'
            }

        }
    }
}
function commonEnumListCertificate(datalist, label = 'label') {
    return function (input, locale) {
        if (!input) {
            return '-'
        }
        let current = datalist.find(item => {
            return item.value == input
        });
        if (current) {
            return current['name']
            // return current[`${label}_${locale}`]
        } else {
            return '-'
        }
    }
}
export const certificateFormat = commonEnumFormatCertificate(certificateList);
export const certificateItemFormat = commonEnumListCertificate(certificateList);

import { companyRole } from "~bac/utils/enumValue";//公司角色类型
export const companyRoleFormat = commonEnumFormat(companyRole)

//当前语言过滤器
export function navLocaleFormat(input) {
    if (input == 'zh') {
        return 'CHN'
    } else {
        return 'EN'
    }
}

import { businessList } from '@/baseUtils/enumValue'
export function getBusinessObject(value, isSelf = false) {      //获取业务对象
    if (!value) return;
    let returnObj = {
        'Convertional': [],
        'Particular Cargo': [],
        'Services': [],
        'Other convertional': [],
        'Dangerous Cargo': []
    }
    let ids;
    if (isSelf) {
        ids = businessList.map(item => {
            return item.id;
        })
    } else {
        ids = value.split(',')
    }
    let filters = [];
    ids.forEach(item => {
        let current = businessList.find(e => {
            return e.id == item
        });
        filters.push(current)
    });
    Object.keys(returnObj).forEach(item => {
        let currentArr = filters.filter(e => {
            return e.name_en == item;
        });
        returnObj[item] = currentArr;
    });
    return returnObj
}

export function bussinessFormat(input, locale = 'en') { //优势业务过滤器
    if (!input) {
        return '-'
    }
    let current = businessList.find(item => {
        return item.id == input
    });
    if (current) {
        return current[`desc_${locale}`]
    } else {
        return '-'
    }
}
import { shippingLine } from "~bac/utils/enumValue";
export function shippingFormat(input, locale = 'en') { //航线过滤器
    if (!input) {
        return '-'
    }
    let current = shippingLine.find(item => {
        return item.id == input
    });
    if (current) {
        return current[`label_${locale}`]
    } else {
        return '-'
    }
}
import { sexList } from "@/baseUtils/enumValue";
export function sexFormat(input, locale) { //性别过滤器
    if (!input) {
        return '-'
    }
    let current = sexList.find(item => {
        return item.value == input
    });
    if (current) {
        return current[`label_${locale}`]
    } else {
        return '-'
    }
}
import { getCityNameById } from '@/baseUtils/AddressConfig'
export const getCityNameFormat = getCityNameById//根据城市id获取城市名

export const fullTextFormat = function (input, length) {        //将富文本过滤为纯文本
    let str = input.replace(/<[^<>]+>/g, '').replace(/&nbsp;/ig, '').replace(/\s+/g, "")
    let result
    if (str.length > length) {
        result = str.slice(0, length) + '...';
    } else {
        result = str.slice(0, length);
    }
    return result;
}

export const moneyFormat = function (value) {  //处理金钱
    if (value == 0) {
        return "0"
    } else {
        return (value / 100)
    }
}


import { levelRequire } from '@/baseUtils/enumValue';//学历
export const levelTypeFormat = commonEnumFormat(levelRequire)

import { experience } from '@/baseUtils/enumValue';//学历
export const experienceFormat = commonEnumFormat(experience)

import { setYears } from "@/baseUtils/enumValue";
export function setYearsFormat(input, locale = 'en') { //成立年限过滤器
    if (!input) {
        return '-'
    }
    let current = setYears.find(item => {
        return item.value == input
    });
    if (current) {
        return current[`label_${locale}`]
    } else {
        return '-'
    }
}

import { distributionMethod } from "@/baseUtils/enumValue"; //询盘分拨方式
export const distributionMethodFormat = commonEnumFormat(distributionMethod);

import { getCounrtyFlagById } from "@/baseUtils/AddressConfig"; //根据国家ID获取国旗
export const countryFlagFormat = getCounrtyFlagById;


import { transportType } from "@/baseUtils/enumValue"; //运输方式
export const shipModeFormat = commonEnumFormat(transportType);
export function shipModeImgFormat(value) {
  //运输方式图标
  let current = transportType.find(item => {
    return item.value == value;
  });
  if (current) {
    return current.img;
  } else {
    return "-";
  }
}

import { packedList } from "@/baseUtils/enumValue"; //打包类型
export const packedFormat = commonEnumFormat(packedList);

import { weightSelect } from "@/baseUtils/enumValue"; //重量单位
export const weightUnitFormat = commonEnumFormat(weightSelect);

import { volumeSelect } from "@/baseUtils/enumValue"; //体积单位
export const volumeUnitFormat = commonEnumFormat(volumeSelect);

import { clauseList } from "@/baseUtils/enumValue"; //贸易条款
export const incotermsFormat = commonEnumFormat(clauseList);
